
import { Vue, Watch } from "vue-property-decorator";
import api from "@/api/exam";

export default class extends Vue {
  examData: any[] = []; // 试卷问题列表
  questionNum = 1; // 题号
  answeroption: any[] = []; // 选项
  answer = ""; // 选中的答案
  answerMap: any = {}; // 最后提交的答案
  bShowTip = false;
  dialogShow = false;
  score = 0;
  @Watch("$route", { immediate: true })
  routerWatch() {
    this.fGetExamQuestions();
  }
  async fGetExamQuestions() {
    (this as any).$route.meta.title = this.$route.query.name;
    let res = await api.examquestion(this.$route.query.id);
    this.examData = res.data;

    this.examData.map((item: any) => {
      if (item.type === 3) {
        this.answerMap[item.id] = [];
      } else {
        this.answerMap[item.id] = "";
      }
    });
    this.answeroption = JSON.parse(
      this.examData[this.questionNum - 1].labelJson
    );
  }
  changeCheckbox() {
    let ans = this.answerMap[this.examData[this.questionNum - 1].id];
    this.answer = ans.join(",");
  }
  changeRadio() {
    this.answer = this.answerMap[this.examData[this.questionNum - 1].id];
  }

  // 翻页函数
  turnpage(arg: number) {
    this.answer = "";
    this.questionNum = this.questionNum + arg;
    this.answeroption = JSON.parse(
      this.examData[this.questionNum - 1].labelJson
    );
    let ans = this.answerMap[this.examData[this.questionNum - 1].id];
    if (ans instanceof Array) {
      this.answer = ans.join(",");
    } else {
      this.answer = ans;
    }
  }
  async subtestvalue() {
    let flag = false;
    Object.keys(this.answerMap).forEach((item: any) => {
      if (this.answerMap[item].length === 0) {
        flag = true;
      }
    });
    if (flag) {
      const confirmResult = await this.$dialog
        .confirm({
          title: "提示",
          message: "存在题目未选择,是否确认提交试卷？",
          confirmButtonColor: "#16BE91",
        })
        .catch((err) => err);
      if (confirmResult === "confirm") {
        this.doScore();
      }
    } else {
      const confirmResult = await this.$dialog
        .confirm({
          title: "提交试卷",
          message: "确认提交试卷",
          confirmButtonColor: "#16BE91",
        })
        .catch((err) => err);
      if (confirmResult === "confirm") {
        this.doScore();
      }
    }
  }
  // 提交答案
  doScore() {
    let obj: any = {};
    for (let key in this.answerMap) {
      // console.log(typeof this.answerMap[key])
      obj[key] =
        typeof this.answerMap[key] === "string"
          ? [this.answerMap[key]]
          : this.answerMap[key];
    }

    api
      .examscore({
        examId: parseInt((this as any).$route.query.id),
        answerMap: obj,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.score = res.data;
          this.dialogShow = true;
        }
      });
  }
  fDialogOpen() {
    this.$router.go(-1);
  }
}
