import request from "@/utils/request";

export default {
  list(arg: any) {
    return request({
      url: "/train/mobile/exam/list",
      method: "GET",
      params: arg,
    });
  },
  // 历史考试列表
  examhistory(arg: any) {
    return request({
      url: "/train/mobile/exam/history/page",
      method: "GET",
      params: arg,
    });
  },
  // 试卷下试题列表
  examquestion(arg: any) {
    return request({
      url: "/train/mobile/exam/question/" + arg,
      method: "GET",
    });
  },
  // 提交答案
  examscore(arg: any) {
    return request({
      url: "/train/mobile/exam/score",
      method: "post",
      data: arg,
    });
  },
};
